import React from 'react'
import { BrowserRouter, Routes, Route, Link, useParams } from "react-router-dom";

const ThankYou = (() => {
    const { id } = useParams();
    let surveyPath = '/survey/' + id; 
    return(
        <div className="paddings127"  style={{textAlign:'center'}}>
            <h3 style={{marginBottom:'1em'}}>Thank You!</h3>
            <div style={{margin:'1em'}}>Please fill out this short survey if you have not.</div>
            <div style={{display:'flex',justifyContent:'center'}}><a className='submit-voting' href={surveyPath}>Survey</a></div>
            <br/>
            <div style={{margin:'0.5em'}}>You may change your response and submit a new one. To do this, simply
                go to the email that we sent to you and click the link.</div>
        </div>
    )

})

export default ThankYou;