import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Link, useParams } from "react-router-dom";
import { IconContext } from "react-icons";
import { HiOutlineThumbUp, HiOutlineThumbDown } from "react-icons/hi";
import { BiCommentDetail, BiNoEntry } from "react-icons/bi";
import { ImCancelCircle } from "react-icons/im";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { TbDownload } from "react-icons/tb";
import { useLocation } from 'react-router-dom';
import MuiSlider from "./MuiSlider.js";
import Viewer from "./Viewer.js";
import { useNavigate } from 'react-router-dom'
import { appBarClasses } from "@mui/material";
import axios from "axios";

const SamplePage = () => {

    const navigate = useNavigate();
    const locationObject = useLocation();

    const molecules = locationObject.state.param1.mols;
    const num_mols = locationObject.state.param1.num_mols;
    // console.log(molecules.length);
    // console.log(num_mols);
    const target = locationObject.state.param2;
    // console.log(target);
    const runInfoUrl = 'https://west.ucsd.edu/limo/?endpoint=run_info&target='+target
    const csvUrl = 'https://west.ucsd.edu/limo/?endpoint=download_csv&target='+target
    const sdfUrl = 'https://west.ucsd.edu/limo/?endpoint=download_sdf&target='+target

    const [runInfo, setRunInfo] = useState('')

    useEffect(() => {     
        document.title = "Try LIMO | LIMO: Latent Inceptionism for Targeted Molecule Generation"; 
    });

    useEffect(() => {
        fetch(runInfoUrl)
        .then((responseObject) => {
            if (!responseObject.ok) {
                throw new Error(responseObject.status);
            }
            setError(null)
            return responseObject.json();
        })
        .then((j) => {
            setRunInfo(j);              
        })
        .catch((error) => {
            setError(error.message);
        });
    },[])

    const [willOpenSurvey, setSurveyPopup] = useState(true);

    const [upIsSelected, selectThumbUp] = useState(        
        JSON.parse(sessionStorage.getItem('thumb_up_arr') == null) ? 
        new Array(molecules.length).fill(false) : JSON.parse(sessionStorage.getItem('thumb_up_arr')))
    const [downIsSelected, selectThumbDown] = useState(        
        JSON.parse(sessionStorage.getItem('thumb_down_arr') == null) ? 
        new Array(molecules.length).fill(false) : JSON.parse(sessionStorage.getItem('thumb_down_arr')))
    const [willShowComment, showComment] = useState(new Array(molecules.length).fill(false));
    const [willShowMore, showMore] = useState(new Array(molecules.length).fill(false));
    const [willOpen, openPopup] = useState(new Array(molecules.length).fill(false));
    const [show3DInfo, set3DInfoVisibility] = useState(new Array(molecules.length).fill(false));
    const [downloadPdbArr, setDownloadPdbVisibility] = useState(new Array(molecules.length).fill(false));
    const [comment, setComment] = useState(
        JSON.parse(sessionStorage.getItem('comment_arr') == null) ? 
        new Array(molecules.length).fill('') : JSON.parse(sessionStorage.getItem('comment_arr')))
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const [qedValue, setValQed] = useState(() => {
        if((JSON.parse(sessionStorage.getItem('qed_value_data'))) == null) {
            return 0;
        } else {
            return (JSON.parse(sessionStorage.getItem('qed_value_data')));
        }
    });
    const [saValue, setValSa] = useState(() => {
        if((JSON.parse(sessionStorage.getItem('sa_value_data'))) == null) {
            return 0;
        } else {
            return (JSON.parse(sessionStorage.getItem('sa_value_data')));
        }
    });
    const [nValue, setValN] = useState(() => {
        if((JSON.parse(sessionStorage.getItem('n_value_data'))) == null) {
            return 0;
        } else {
            return (JSON.parse(sessionStorage.getItem('n_value_data')));
        }
    });
    const [oValue, setValO] = useState(() => {
        if((JSON.parse(sessionStorage.getItem('o_value_data'))) == null) {
            return 0;
        } else {
            return (JSON.parse(sessionStorage.getItem('o_value_data')));
        }
    });
    const [autoDockValue, setValAutoDock] = useState(() => {
        if((JSON.parse(sessionStorage.getItem('autodock_value_data'))) == null) {
            return 0;
        } else {
            return (JSON.parse(sessionStorage.getItem('autodock_value_data')));
        }
    });
    const [hValue, setValH] = useState(() => {
        if((JSON.parse(sessionStorage.getItem('h_value_data'))) == null) {
            return 0;
        } else {
            return (JSON.parse(sessionStorage.getItem('h_value_data')));
        }
    });
    
    const handleChangeQed = selectedValue => {sessionStorage.setItem('qed_value_data', 
        JSON.stringify(selectedValue));setValQed(selectedValue);};
    const handleChangeSa  = selectedValue => {sessionStorage.setItem('sa_value_data', 
    JSON.stringify(selectedValue));setValSa(selectedValue);};
    const handleChangeN   = selectedValue => {sessionStorage.setItem('n_value_data', 
    JSON.stringify(selectedValue));setValN(selectedValue);};
    const handleChangeO   = selectedValue => {sessionStorage.setItem('o_value_data', 
    JSON.stringify(selectedValue));setValO(selectedValue);};
    const handleChangeAutoDock   = selectedValue => {sessionStorage.setItem('autodock_value_data', 
    JSON.stringify(selectedValue));setValAutoDock(selectedValue);};
    const handleChangeH   = selectedValue => {sessionStorage.setItem('h_value_data', 
    JSON.stringify(selectedValue));setValH(selectedValue);};

    function changeThumbUpColor(ind) {
        const thumbUpArr = upIsSelected.map((value, index) => index === ind ? !value : value);
        const thumbDownArr = downIsSelected.map((value, index) => index === ind ? false : value);
        selectThumbUp(thumbUpArr);
        selectThumbDown(thumbDownArr);
        sessionStorage.setItem('thumb_up_arr',JSON.stringify(thumbUpArr));
        sessionStorage.setItem('thumb_down_arr',JSON.stringify(thumbDownArr));
    }

    function changeThumbDownColor(ind) {
        const thumbDownArr = downIsSelected.map((value, index) => index === ind ? !value : value);
        const thumbUpArr = upIsSelected.map((value, index) => index === ind ? false : value);
        selectThumbDown(thumbDownArr);
        selectThumbUp(thumbUpArr);
        sessionStorage.setItem('thumb_up_arr',JSON.stringify(thumbUpArr));
        sessionStorage.setItem('thumb_down_arr',JSON.stringify(thumbDownArr));   
    }

    function changeVisibility(ind) {
        const showMoreArr = willShowMore.map((value, index) => index === ind ? !value : false);
        const textboxArr = willShowComment.map((value, index) => value = false);
        showMore(showMoreArr);
        showComment(textboxArr);
    }

    function setTextbox(ind) {
        const textboxArr = willShowComment.map((value, index) => index === ind ? !value : false);
        const showMoreArr = willShowMore.map((value, index) => value = false);
        showComment(textboxArr);
        showMore(showMoreArr);
    }

    function setPopup(ind) {
        const popupArr = willOpen.map((value, index) => 
            index === ind ? !value : false
        );
        const showMoreArr = willShowMore.map((value, index) => 
            value = false
        );
        openPopup(popupArr);
        showMore(showMoreArr);
    }

    function handleCommentChange(commentText, ind) {
        // console.log(commentText);
        const commentArr = comment.map((value, index) => 
            index === ind ? commentText : value
        );
        setComment(commentArr);
        sessionStorage.setItem('comment_arr',JSON.stringify(commentArr));
    }

    function sliderClick() {
        setLoading(true)

        sessionStorage.removeItem('thumb_up_arr');
        sessionStorage.removeItem('thumb_down_arr');
        sessionStorage.removeItem('comment_arr');

        //get slider values
        var qed_slider = qedValue;
        var sa_slider = saValue;
        var nitrogen_slider = nValue; 
        var oxygen_slider = oValue;
        var autodock_slider = autoDockValue;
        var hydrogen_slider = hValue;

        //plug into url
        // var url = "https://west.ucsd.edu/limo/?endpoint=get_mols?target="+target+"&qed_slider="+qed_slider
        // +"&sa_slider="+sa_slider+"&nitrogen_slider="+nitrogen_slider+"&oxygen_slider="+oxygen_slider;
        var url = "https://west.ucsd.edu/limo/?endpoint=get_mols&target="+target+"&qed_slider="+qed_slider
        +"&sa_slider="+sa_slider+"&autodock_slider="+autodock_slider+"&hydrogen_bonds="+hydrogen_slider;

        //fetch
        fetch(url)
            .then((responseObject) => {
                if (!responseObject.ok) {
                    throw new Error(responseObject.status);
                }
                return responseObject.json();
            })
            .then((j) => {
                // console.log(j);
                // console.log(target);
                //update
                locationObject.state.param1 = j;
                // console.log(locationObject.state.param1);
                locationObject.state.param2 = target;
                navigate("/sample-page", {replace: true, state: {param1: j, param2: target}}); 
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
                // setError(error.message);
                alert(error);
            });

    }

    function handleSubmission() {
        molecules.map((e, index) => {
            if(upIsSelected[index]) {
                const url1 = 
                    "https://west.ucsd.edu/limo/?endpoint=record_thumbs&target="+target+"&smiles="+e.smiles+"&response=1";
                axios.get(url1)
                    .then((response1) => {
                        // console.log(response1);
                    })
                    .catch((error1) => {
                        alert(error1);
                    })
            }
            if(downIsSelected[index]) {
                const url2 = 
                    "https://west.ucsd.edu/limo/?endpoint=record_thumbs&target="+target+"&smiles="+e.smiles+"&response=-1";
                axios.get(url2)
                    .then((response2) => {
                        // console.log(response2);
                    })
                    .catch((error2) => {
                        alert(error2);
                    })
            }
            if(comment[index].trim().length !== 0) {
                const string = comment[index].trim();
                const url3 = 
                    "https://west.ucsd.edu/limo/?endpoint=record_comment&target="+target+"&smiles="+e.smiles+"&response="+string;
                axios.get(url3)
                    .then((reponse3) => {
                        // console.log(reponse3);
                    })
                    .catch((error3) => {
                        alert(error3);
                    })
            }
            // itemWork(i, e.smiles, e.autodock_affin, e.amber_affin, e.logp, e.qed, e.sa, e.img)
        })
        // console.log("reached")
        sessionStorage.clear();
        navigate("/thank-you/"+target)
    }

    function showVisualizationInfo(ind) {
        const show3DInfoArr = show3DInfo.map((value, index) => 
        index === ind ? !value : false
        );
        set3DInfoVisibility(show3DInfoArr);
    }

    function changeDownloadPdbVisibility(ind) {
        const showDownloadPdbArr = downloadPdbArr.map((value, index) => 
        index === ind ? !value : false
        );
        setDownloadPdbVisibility(showDownloadPdbArr);
    }

    let itemWork = (index, smiles, autodock, hydrogen_bonds , pi_pi, salt_bridges, t_stacking, synthetic_steps, 
        logp, qed, sa, image) => {

        const urlImg = "https://west.ucsd.edu/limo/?endpoint=img&img_name=" + image;
        const url3D = "https://west.ucsd.edu/limo/?endpoint=get_structure&smiles="+smiles+"&target="+target;
        var isLongSmiles = false;
        if(smiles.length > 30){
            isLongSmiles = true;
        }
        return (
            <IconContext.Provider value={{color:"orange", style:{width:'22px',height:'22px',margin:'0 8px 0 8px'}}}>
            {/* Item Work */}
            <div className="col-md-4 desing">
                <div className="item-work">
                    <a href={url3D}>
                        <TbDownload style={{color:'black',width:'18px',height:'18px',margin:'0'}}
                         className="download-pdb-button"
                        onMouseEnter={()=>changeDownloadPdbVisibility(index)} 
                        onMouseLeave={()=>changeDownloadPdbVisibility(index)}
                        />
                    </a>
                    <div className="visualization-button" onClick={() => setPopup(index)}>3D
                    <AiOutlineQuestionCircle style={{color:'#999',width:'14px',height:'14px',margin:'0'}}
                    onMouseEnter={()=>showVisualizationInfo(index)} onMouseLeave={()=>showVisualizationInfo(index)}/>
                    </div>
                    {downloadPdbArr[index] && 
                        <div className='all-tooltip'>Download PDB</div>
                    }
                    {show3DInfo[index] && 
                        <div className='all-tooltip' style={{right:'0',width:'200px'}}>Visualizes 3D pose of the ligand docked against the input protein. 
                        Docking performed with AutoDock-GPU.</div>
                    }
                    {willOpen[index] && 
                        <div className="cover">
                            <div className="box"  style={{textAlign:'center'}}>
                                <ImCancelCircle className="close-icon" onClick={()=>setPopup(index)}/>
                                <div><Viewer url={url3D}/></div>
                            </div>
                        </div>
                    }
                    <div className="hover">
                        <img src={urlImg} alt="molecule binder visualization"/>
                    </div>                                   
                    <div className="info-work">
                        {isLongSmiles?
                            <div style={{lineHeight:'14pt'}}>
                                SMILES: {smiles.slice(0,30)} <br/>
                                {smiles.slice(30,smiles.length)}
                            </div>
                            :
                            <div style={{lineHeight:'14pt'}}>SMILES: {smiles}</div>
                        }
                        <div>AutoDock affinity (M): {autodock.toExponential(3)}</div>
                        <div>
                            <button className='fancy-button' 
                            onMouseEnter={() => changeVisibility(index)} onMouseLeave={() => changeVisibility(index)}>
                                Show More
                            </button>
                            {willShowMore[index] && 
                                <div className="moreInfo">
                                    <div>Num. hydrogen bonds: {hydrogen_bonds.toFixed(0)}</div>
                                    <div>Num. pi-pi interactions: {pi_pi.toFixed(0)}</div>
                                    <div>Num. salt bridges: {salt_bridges.toFixed(0)}</div>
                                    <div>Num. t-stacking: {t_stacking.toFixed(0)}</div>
                                    <div>Synthetic steps estimation: {synthetic_steps.toFixed(3)}</div>

                                    <div>logP: {logp.toFixed(3)}</div>
                                    <div>SA: {sa.toFixed(3)}</div>
                                    <div>QED: {qed.toFixed(3)}</div>
                                    
                                </div>  
                            }
                        </div>
                        <div className="icons-work">
                            <HiOutlineThumbUp id="thumbs" onClick={() => changeThumbUpColor(index)} 
                                style={{fill: upIsSelected[index] ? '#FFB43F':''}}/>
                            <HiOutlineThumbDown id="thumbs" onClick={() => changeThumbDownColor(index)} 
                                style={{fill: downIsSelected[index] ? '#FFB43F':''}}/> 
                            <BiCommentDetail id="thumb3" onClick={() => setTextbox(index)} 
                                style={{fill: willShowComment[index] ? 'brown':''}}/> 
                            {willShowComment[index] && 
                                <div className="commentArea">
                                    <textarea className="textboxComment" value={comment[index]} 
                                        onChange={(e) => handleCommentChange(e.target.value, index)} name="Comment"/>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            </IconContext.Provider>
        );
    };

    function hidePopup() {
        setSurveyPopup(false)
    }

    let popupStyle = willOpen ? {display:'block'} : {display:'none'};

    return (
        /* layout*/
        <div id="layout" className="paddings127">
            <h1 className="page-header">LIMO Results</h1>
            <table>
                <tbody>
                <tr><td>Run ID </td> <td>{runInfo.run_id}</td></tr>
                <tr><td>PDB</td> <td>{runInfo.pdb_name}</td></tr>
                <tr><td>Binding site<br/>coordinates</td> <td>X: {runInfo.x}<br/>Y: {runInfo.y}<br/>Z: {runInfo.z}</td></tr>
                <tr><td>Radius</td><td>{runInfo.radius}</td></tr>
                {runInfo.date_started === 'N/A' ? '':<tr><td>Date submitted</td><td>{runInfo.date_started}</td></tr>}
                </tbody>
            </table>
            <br/>           
            <div className="content127">     
                <div className="downloadCSV">
                    <button className="fancy-button" style={{marginRight:'3px'}}>
                    <TbDownload style={{color:'white',width:'18px', height:'18px',marginBottom:'-2px'}}/><a href={csvUrl} style={{color:'white',textDecoration:'none'}}> Results (CSV File)</a>
                    </button>
                    <button className="fancy-button" style={{marginLeft:'3px'}}>
                    <TbDownload style={{color:'white',width:'18px', height:'18px',marginBottom:'-2px'}}/><a href={sdfUrl} style={{color:'white',textDecoration:'none'}}> Results (SDF File)</a>
                    </button>
                </div>
                <br/>
                <div className="text-description">
                This page shows molecules generated by LIMO given your input protein. See our <a href='/intro'>
                    Intro</a> page for more information.
                </div>
                <div style={{color:"#01b5c6", fontSize:"large",marginTop:'1em',fontWeight:'bold'}}>Filter Molecules</div>
                <p className="text-description" style={{textAlign:'center'}}>
                    Pull sliders to the right to filter out molecules with poor property scores. <br/>
                    The higher the value on the slider, the more stringent the filtering is for that property.
                </p>
                <div style={{display:"flex", margin:"auto", marginTop:"0.8em",marginBottom:'1em'}}>
                    
                    <div style={{textAlign:"right"}}>
                        <MuiSlider param="QED" val={qedValue} handleValChange={handleChangeQed}/>
                        <MuiSlider param="SA" val={saValue} handleValChange={handleChangeSa}/>
                        {/* <MuiSlider param="N" val={nValue} handleValChange={handleChangeN}/>
                        <MuiSlider param="O" val={oValue} handleValChange={handleChangeO}/> */}
                        <MuiSlider param="AutoDock affinity" val={autoDockValue} handleValChange={handleChangeAutoDock}/>
                        <MuiSlider param="Hydrogen bonds" val={hValue} handleValChange={handleChangeH}/>
                    </div>
                    <button className="generate-button" onClick={sliderClick}  
                        style={{color:"white",backgroundColor:"#00C6D7"}}>
                        Filter 
                    </button>
                </div>

                <div style={{textAlign:'center',fontWeight:'bold'}}>Showing {molecules.length} of {num_mols} ligands</div>
            </div>
            {/* Works */}
            <section className="paddings">
                {/* Container */}
                <div className="container">
                { loading ? <div style={{textAlign:'center',color:'#eaeaea'}}>Loading...</div> :
                    /* Items Works filters*/
                    <div className="works portfolioContainer">

                        {molecules.map((e, i) => {
                            return (
                                itemWork(i, e.smiles, e.autodock_affin, e.hydrogen_bonds , e.pi_pi, e.salt_bridges, 
                                    e.t_stacking, e.synthetic_steps , e.logp, e.qed, e.sa, e.img)
                            )
                        })}
                    </div>   
                    /* End Items Works filters*/
                }     
                </div>
                {/* End Container*/}
            </section>
            {/* End Works*/}
            {/* <div className='submit-voting' onClick={handleSubmission}>Submit</div> */}
        </div>
        /* End layout*/

    );
};

export default SamplePage;