import React from "react";
import { Link } from "react-router-dom";

const footer = () => {
    return(
        // <!-- footer bottom-->
        <footer class="footer-bottom">
            <div class="container">
               <div class="row">   
                    <ul class="menu-footer" style={{textAlign: "center"}}>
                            <li><Link to="/terms-of-use">Terms of Use</Link></li>
                    </ul>                                              
                    {/* <!-- Nav--> */}
                    {/* <div class="col-md-8" s> */}
                        {/* <div class="logo-footer">
                            <h2>LIMO</h2>
                        </div> */}
                        {/* <!-- Menu--> */}
                        
                        {/* <!-- End Menu--> */}

                        {/* <!-- coopring--> */}
                       {/* <div class="row coopring">
                           <div class="col-md-8">
                               <p>&copy; 2015 Roken . All Rights Reserved.  1995 - 2015</p>
                           </div>
                       </div>     */}
                       {/* <!-- End coopring-->   */}
                    </div>
                    {/* <!-- End Nav--> */}

                    {/* <!-- Social--> */}
                    {/* <div class="col-md-4">
                        {/* <!-- Menu--> */}
                        {/* <ul class="social">
                            <li data-toggle="tooltip" title data-original-title="Facebook">
                                <a href="#" target="_blank"><i class="fa fa-facebook"></i></a>
                            </li> 
                            <li data-toggle="tooltip" title data-original-title="Twitter">
                                <a href="#" target="_blank"><i class="fa fa-twitter"></i></a>
                            </li> 
                            <li data-toggle="tooltip" title data-original-title="Youtube">
                                <a href="#" target="_blank"><i class="fa fa-youtube"></i></a>
                            </li>                     
                        </ul> */}
                        {/* <!-- End Menu--> */}
                    {/* </div> */}
                    {/* <!-- End Social--> */}

               {/* </div>  */}
                    
            </div>
        </footer>      
        // <!-- End footer bottom-->
    )
}

export default footer;