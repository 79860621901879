import React, {useEffect} from "react";
//src: https://www.sdsc.edu/terms_of_use.html

const TermOfUse = () => {
    useEffect(() => {
        document.title="Terms of Use | LIMO: Latent Inceptionism for Targeted Molecule Generation";
    })
    return (
        <div className="paddings127">
            <h1 className="page-header">Terms and Condition of Use</h1>
            <br></br>
            <div className="child-content" style={{width:"70%"}}>
                <div style={{textAlign:"left",}}>
                    <ul>
                        <p>Please read these terms carefully before using www.limo-aimd.com. Your use or access of this Web Site
                             means you have read, understood and agreed to these terms. Please leave this Web Site and do not use 
                             or access this Web Site if you do not agree to the terms set forth herein.

                            We reserve the right, at our discretion, to change, modify, add, or remove portions of these terms at 
                            any time WITHOUT NOTICE. Please check these terms periodically for changes. Your continued use of 
                            these Sites following the posting of changes to these terms will mean you accept those changes.
                        </p>

                        <br></br>
                        <h3>PRIVACY POLICY</h3>
                        <p>
                        AIMD Lab is committed to your privacy within the applicable laws of the State of California and the 
                        Policies of the University of California. This disclosure includes:
                        </p>
                        <ol>
                            <li>What personally identifiable information of yours is collected from you through the website</li>
                            <li>The organization collecting the information</li>
                            <li>How the information is used</li>
                            <li>With whom the information may be shared</li>
                            <li>The choices available to you regarding collection, use and distribution of the information</li>
                            <li>The kind of security procedures that are in place to protect the loss, misuse or alteration of 
                                information under AIMD control</li>
                            <li>How you can correct any inaccuracies in the information</li>
                        </ol>
                        <p>
                            Please contact AIMD at ai-drug-g@ucsd.edu if you feel we're not abiding by our posted privacy notice.
                        </p>

                        <br></br>
                        <h3>INFORMATION COLLECTION AND USE</h3>
                        <p>
                        AIMD is the sole owner of the information collected on this site. We will not sell, share, or rent this 
                        information to others in ways different from what is disclosed in this statement. AIMD collects 
                        information from our users at several different points on our website. The information is used exclusively
                        to deliver information and services to you.
                        </p>

                        <br></br>
                        <h3>DISCLAIMER</h3>
                        <p>
                        THE MATERIALS IN THIS SITE ARE PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS OR 
                        IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, AIMD DISCLAIMS ALL WARRANTIES, 
                        EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS 
                        FOR A PARTICULAR PURPOSE. AIMD DOES NOT WARRANT THAT THE FUNCTIONS CONTAINED IN THE MATERIALS WILL BE 
                        UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THIS SITE OR THE SERVER THAT MAKES 
                        IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. AIMD DOES NOT WARRANT OR MAKE ANY 
                        REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE OF THE MATERIALS IN THIS SITE IN TERMS OF 
                        THEIR CORRECTNESS, ACCURACY, RELIABILITY, OR OTHERWISE. YOU (AND NOT AIMD) ASSUME THE ENTIRE COST OF ALL 
                        NECESSARY SERVICING, REPAIR, OR CORRECTION. APPLICABLE LAW MAY NOT ALLOW THE EXCLUSION OF IMPLIED 
                        WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU. LIMITATION OF LIABILITY UNDER NO CIRCUMSTANCES, 
                        INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, SHALL AIMD BE LIABLE FOR ANY SPECIAL OR CONSEQUENTIAL DAMAGES 
                        THAT RESULT FROM THE USE OF, OR THE INABILITY TO USE, THE MATERIALS IN THIS SITE, EVEN IF AIMD OR AN AIMD 
                        AUTHORIZED REPRESENTATIVE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. APPLICABLE LAW MAY NOT 
                        ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY OR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE 
                        LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. IN NO EVENT SHALL AIMD’S TOTAL LIABILITY TO YOU FOR ALL 
                        DAMAGES, LOSSES, AND CAUSES OF ACTION (WHETHER IN CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO, 
                        NEGLIGENCE), OR OTHERWISE) EXCEED THE AMOUNT PAID BY YOU, IF ANY, FOR ACCESSING THIS SITE.
                        </p>

                    </ul>
                </div>                   
            </div>          
        </div>
    );
};

export default TermOfUse;