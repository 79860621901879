import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const InputSubmitted = () => {
    let navigate = useNavigate();

    const curUrl = window.location.href;
    const curUrlComponents = curUrl.split('/');
    const target = curUrlComponents[curUrlComponents.length-1];
    const runInfoUrl = 'https://west.ucsd.edu/limo/?endpoint=run_info&target='+target;
    const path = '/rate-molecule-binders/'+target;

    const [error, setError] = useState('');
    const [runInfo, setRunInfo] = useState('');
    const [wrongRunId, setWrongRunId] = useState(true);

    useEffect(() => {
        fetch(runInfoUrl)
        .then((responseObject) => {
            if (!responseObject.ok) {
                throw new Error(responseObject.status);
            }
            setError(null)
            return responseObject.json();
        })
        .then((j) => {
            if(j.pdb_name=='N/A' && j.x=='N/A' && j.y=='N/A' && j.z=='N/A' && j.radius=='N/A') {
                setWrongRunId(true)
                alert('No Data Found!')
                navigate('/')
            }
            else {
                setWrongRunId(false)
                setRunInfo(j)  
            }           
        })
        .catch((error) => {
            setError(error.message);
            alert(error)
        });
    },[])

    return (
        <div className='paddings127'>
            {wrongRunId ? '' : 
            <>
                <h3 style={{textAlign:'center',marginBottom:'1em'}}>Submitted!</h3>
                <table>
                    <tbody>
                    <tr><td>Run ID </td> <td>{runInfo.run_id}</td></tr>
                    <tr><td>PDB</td> <td>{runInfo.pdb_name}</td></tr>
                    <tr><td>Binding site<br/>coordinates</td> <td>X: {runInfo.x}<br/>Y: {runInfo.y}<br/>Z: {runInfo.z}</td></tr>
                    <tr><td>Radius</td><td>{runInfo.radius}</td></tr>
                    {runInfo.date_started == 'N/A' ? '':<tr><td>Date submitted</td><td>{runInfo.date_started}</td></tr>}
                    </tbody>
                </table>
                <br/>
                <div style={{display:'grid'}}>
                    <button style={{margin:'auto',border:'none'}} onClick={()=>window.print()}>Print</button>
                </div>
                <br/>
                A confirmation email has been sent to you. You will receive another email from us when results are ready.
                <br/>
                <div style={{margin:'0.5em 0'}}>Check status of this run <a href={path}>here</a>.
                </div>
                <div>You may try another protein after navigating to the <a href='/try-limo'>Try LIMO</a> page.</div>
            </>}
        </div>
    )
}

export default InputSubmitted;