import React, { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom'

//js 1
import 'intro.js/introjs.css';
//sampleScreenShot1 for pc
import sampleScreenShot1 from '../images/sample1.png'
//sampleScreenShot2 for mobile
import sampleScreenShot2 from '../images/sample2.png'
import { Steps, Hints } from 'intro.js-react';
import { margin } from "@mui/system";

//js 2
// import Joyride from 'react-joyride';

//js 3
// import * as Driver from 'driver.js';
// import 'driver.js/dist/driver.min.css';


const Home = () => {
    useEffect(() => {
        document.title = "LIMO: Latent Inceptionism for Targeted Molecule Generation";  
    })

    const navigate = useNavigate();
    const id = "f51f21a2a32c085a";
    //329034a915beb712
    
    const [error, setError] = useState('');

    function sampleLink() {
    
        sessionStorage.clear();
    
        let qedValue = 0;
        let saValue = 0;
        let nValue = 0;
        let oValue = 0;
        let autodockValue = 0;
        let hValue = 0;
    
        const url = 'https://west.ucsd.edu/limo/?endpoint=get_mols&target='+id+'&qed_slider='+qedValue
        +'&sa_slider='+saValue+'&autodock_slider='+autodockValue+'&hydrogen_bonds='+hValue;
            // console.log(url)
            // https://west.ucsd.edu:12805/limo?endpoint=get_mols&target=f51f21a2a32c085a&qed_slider=0&sa_slider=0&autodock_slider=0&hydrogen_bonds=0
            // https://west.ucsd.edu:12805/get_mols?target=f51f21a2a32c085a&qed_slider=0&sa_slider=0&autodock_slider=0&hydrogen_bonds=0

        fetch(url, {method:'get'})
            .then((responseObject) => {
                console.log(responseObject);
                if (!responseObject.ok) {
                    throw new Error(responseObject.status);
                }
                return responseObject.json();
            })
            .then((j) => {
                console.log(j);
                // console.log(id);
                navigate("/sample-page", {state: {param1: j, param2: id}});               
            })
            .catch((error) => {
                setError(error.message);
                // alert(error);
            });
    }

    let state = {
        stepsEnabled: false,
        initialStep: 0,
        steps : [
            {
                element: '#b1',
                intro: 'A one-time survey',
                position: 'right',
                tooltipClass: 'myTooltipClass',
                highlightClass: 'myHighlightClass',
                nextLabel: "Next", doneLabel: "Done",
            },
            {
                element: '#b2',
                intro: 'a demo of the main functionality of the website',
                position: 'right',
                nextLabel: "Next", doneLabel: "Done",
            },
            {
                element: '#b3',
                intro: 'Click to generate your molecule binders',
                position: 'right',
                nextLabel: "Next", doneLabel: "Done",
                hideNext: 'false',skipLabel:"x"
            },
        ],
        hintsEnabled: true,
        hints: [
            {
                element: "#b0",
                hint: "Follow me to fulfill the workflow of LIMO",
                hintPosition: "top-right"
            },
            {
                element: '#b1',
                hint: 'Firstly please fill out the survey',
                hintPosition: "top-right"
            }
        ]
    };


        //change styles according to device types
        var isSmallSceen = false;
        if (window.screen.width < 479) {
            isSmallSceen = true;
        } else {
        }

        let toggleSteps = () => {
            this.setState(prevState => ({ stepsEnabled: !prevState.stepsEnabled }));
        };
    
        let onExit = () => {
            this.setState(() => ({ stepsEnabled: false }));
        };

        
        return (
            <>
            <section className="content_info">
                <div style={{position:'absolute',textAlign:'center',
                    height:'100%', width:'100%', backgroundColor:'#19191987',padding:'3%'}}>
                    <h1 className="homepage-header">
                        Welcome to LIMO
                    </h1>
                    <div style={{height:'6vh'}}></div>
                    <div style={{fontSize:'large',lineHeight:'21pt'}}>LIMO generates 
                        and visualizes potential ligands for a given protein. 
                    </div>
                    <div style={{height:'6vh'}}></div>
                    <button className="fancy-button" onClick={() => navigate('/try-limo')} 
                    style={{fontSize:'large',padding:'4px 12px'}}> Try LIMO </button>
                    <div style={{height:'10vh'}}></div>
                </div>  
            </section>   

            {isSmallSceen? 
                <div>
                    <div style={{backgroundColor:"#bfd3d6",padding:"50pt",textAlign:"center" ,justifyContent:"center"}}>
                        
                        {/* <Link className='home-button' to="/demo">Demo</Link>
                        <Link className='home-button' id="b3" to="/intro" >Learn more</Link> */}
                        <div style={{marginBottom:"30pt"}}>
                            <Link id="b1" to="/demo" style={{color:"black",fontSize: "large"}}>Demo Video</Link>
                        </div>
                        <div style={{marginBottom:"30pt"}}>
                            <Link id="b1" to="/intro" style={{color:"black",fontSize: "large"}}>What Is LIMO?</Link>
                        </div>
                        <div style={{marginBottom:"30pt"}}>
                            <Link id="b1" to="/people" style={{color:"black",fontSize: "large"}}>People</Link>
                        </div>
                        <Link id="b1" to="/contact-us" style={{color:"black",fontSize: "large"}}>Contact</Link>

                    </div>
                    <div style={{backgroundColor:"#f3f2ef",padding:"50pt",display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                        <div id="b1" className='homepage-links' onClick={sampleLink}>
                            <div style={{paddingBottom:'1em'}}>View a Sample</div>
                            <img src={sampleScreenShot1} alt="" 
                            style={{border:'2px solid #ececea', width:"640px",maxWidth:'100%'}}></img>
                        </div>
                        <br></br>
                            
                    </div>
                </div>
                :
                <div>   
                    <div style={{backgroundColor:"#bfd3d6", display: 'flex',justifyContent: 'space-evenly', padding:'3em'}}>
                        
                        <Link id="b1" to="/demo" style={{color:"black",fontSize: "large"}}>Demo Video</Link>
                        <Link id="b1" to="/intro" style={{color:"black",fontSize: "large"}}>What Is LIMO?</Link>

                    </div>
                    <div style={{backgroundColor:"#f3f2ef", padding:"3em",display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                        <div id="b1" className='homepage-links' onClick={sampleLink}>
                            <div style={{paddingBottom:'0.5em'}}>View a Sample</div>
                            <img src={sampleScreenShot1} alt="" 
                            style={{border:'2px solid #ececea', width:"640px",maxWidth:'100%'}}></img>
                        </div>
                        <br></br>
                            
                    </div>
                </div>
            }
            

            <div style={{clear:"both"}}></div>
            
            {/* <div className="paddings127" >
                <h4 style={{textAlign:"center"}}>** This website is currently under construction. **</h4>
            
                <table id="home-page-table-2" style={{borderSpacing:borderSpace}} >
                    <tbody>
                            
                        <tr>
                            <td style={{fontSize:Font}}>
                                Start a tour of LIMO
                            </td>
                            <td>
                                <div className="home-button" id="b0" onClick={this.toggleSteps} 
                                    style={{fontSize:Font, padding: homeButtonPadding}}>
                                    Start the Tour
                                </div>
                                
                            </td>
                        </tr>
                        <tr>
                            <td style={{fontSize:Font}}>
                                Before all the fun, please tell us a little about you
                            </td>
                            <td>
    
                                <Link className="home-button" id="b1" to="/questionnaire" 
                                    style={{fontSize:Font, padding: homeButtonPadding}}>
                                    Fill In a Questionnaire
                                </Link>
                            </td>
                        </tr>
                        <tr>
                            <td style={{fontSize:Font}}>
                                This website generates all the potential 
                                candidates that bind well with the given protein
                            </td>
                            <td>

                                <Link className="home-button" id="b2" to="molecule-generation" 
                                    style={{fontSize:Font, padding: homeButtonPadding}}>
                                    View Sample Output
                                </Link>
    
                            </td>
                        </tr>
                        <tr>
                            <td style={{fontSize:Font}}>
                                You can upload your protein file or search its PDB ID here and 
                                explore its potential binders
                            </td>
                            <td>
                                <Link className="home-button" id="b3" to="/upload-pdb" 
                                    style={{fontSize:Font, padding: homeButtonPadding}}>Try LIMO</Link>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Steps
                    enabled={stepsEnabled}
                    steps={steps}
                    initialStep={initialStep}
                    onExit={this.onExit}
                />
                <Hints enabled={hintsEnabled} hints={hints} />
            </div> */}
            </>
        );
}



export default Home;