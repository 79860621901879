import React , {useEffect} from "react";
import { Container } from "react-bootstrap";

export default function Demo() {
    return <div className="paddings127" style={{paddingTop:'50px'}}>
        <Container>
            <div className="content127">
            <h1 className="page-header">LIMO Demo Video</h1>
            <div className='paddings127'  style={{marginTop:'-20px'}}>
                <iframe width="840px" height="530px" src="https://youtube.com/embed/69yvC6m6MpU"></iframe>
            </div>
            </div>
        </Container>
    
    </div>
    
}
