import React, {useEffect} from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import rose from "../images/rose.jpg";
import mike from "../images/gilson.jpg";
import oliver from "../images/oliver.jpeg";
import peter from "../images/peter.jpg";
import bo from "../images/bo_zhao.jfif";
import mudong from "../images/mudong.png";
import MemberCard from "./MemberCard.jsx";
import wuyou from "../images/wuyou.jpg";
import zimo from "../images/zimo.jpg";


const AboutUs = () => {
    useEffect(() => {
        document.title = "People | LIMO: Latent Inceptionism for Targeted Molecule Generation";
    });

    return (
        <div className="paddings127">
            <h1 className="page-header">Meet the People</h1>
            <p style={{fontSize:'medium',margin:'0', textAlign:"center"}}>
                Our group consists of students and researchers at the University of California San Diego.
            </p>

            <br></br>

            <h3 style={{textAlign:'center'}}>Researchers</h3>
            <h4 className='people-page-subheading'>Professors</h4>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div className="member-set" style={{width:"60%"}}>
                    <MemberCard imgSrc={rose} 
                        personName='Rose Yu' 
                        dept='Department of Computer Science and Engineering'
                        websiteLink='https://roseyu.com/index.html#' 
                    />
                    <MemberCard imgSrc={mike} 
                        websiteLink='https://gilson.cloud.ucsd.edu/' 
                        personName='Michael K. Gilson' 
                        dept='Department of Chemistry and Biochemistry'
                        school='Skaggs School of Pharmacy and Pharmaceutical Sciences'
                    />
                </div>
            </div>

            <h4 className='people-page-subheading'>Students</h4>
            <div  className="member-set">
            <MemberCard personName='Peter Eckmann' 
                imgSrc={peter}
                dept='Department of Computer Science and Engineering'/>
            <MemberCard personName='Kunyang Sun' 
                imgSrc={oliver}
                dept='Department of Chemistry and Biochemistry'/>
            <MemberCard personName='Bo Zhao' 
                imgSrc={bo}
                dept='Department of Computer Science and Engineering'/>
            <MemberCard personName='Mudong Feng'
                imgSrc={mudong} 
                dept='Department of Chemistry and Biochemistry'/>
            </div>

            <br></br>

            <h3 style={{textAlign:'center'}}>Application Developers</h3>
            <h4 className='people-page-subheading'>Students</h4>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
                <div  className="member-set" style={{width:"70%"}}>
                <MemberCard personName='Zimo Peng' 
                    imgSrc={zimo}
                    major='Department of Electrical and Computer Engineering'/>
                <MemberCard personName='You Wu' 
                    imgSrc={wuyou}
                    major='Department of Electrical and Computer Engineering'/>
                <MemberCard personName='Peter Eckmann' 
                    imgSrc={peter}
                    dept='Department of Computer Science and Engineering'/>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;