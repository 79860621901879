import React from 'react'
import { BrowserRouter, Routes, Route, Link, useParams } from "react-router-dom";

const SurveySubmitted = (() => {
    return(
        <div className="paddings127"  style={{textAlign:'center'}}>
            <h3>Thank you for filling out the survey!</h3>
        </div>
    )

})

export default SurveySubmitted;