//reference: https://scrimba.com/learn/learnreact/forms-in-react-select-option-co83b466d859cf1d6c4b3efaf
import React from 'react'
import axios from 'axios'
import Viewer from "./Viewer.js";
import { useEffect, useState } from 'react';
import { ImCancelCircle } from "react-icons/im";
import { IconContext } from "react-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { BiExit } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

const BindingSite = ({Id, res, resNum, x, y, z, email, setIsActive, setIsSiteActive, files, pdbId, url, isLoading,residueName, maxIC50}) => {
    const navigate = useNavigate()
    const [willOpen, setPopup] = useState(false);
    let path = "/input-submitted/"+Id;
    const submitJob = (event) => {
        setIsActive(true);
        //console.log(email);
        const url = "https://west.ucsd.edu/limo/?endpoint=submit_job&target_id="+Id+"&x="+x+"&y="+y+"&z="+z+"&email="+email+"&res="+res+"&maxIC50="+maxIC50;
        //console.log(url)
        const recordQueryURL = "https://west.ucsd.edu/limo/?endpoint=record_query&PDB="+pdbId+"&x="+x+"&y="+y+"&z="+z+"&email="+email+"&maxIC50="+maxIC50;
        axios.get(recordQueryURL);
        //uncomment lines below and comment lines above
        axios.get(url)
            .then((responseObject) => { 
                // console.log("submit_job: ",responseObject);
                // console.log("success");
                navigate(path, {state: {runId:Id,x:x,y:y,z:z,files:files,pdbId:pdbId,residueName:residueName,maxIC50:maxIC50}})
                console.log("submit_job: ",responseObject);
            })
            .catch((err) => {
                alert(err);
            });

    }

    const returnStep = (e) => {
        setIsSiteActive(false);

    }

    return (
        <IconContext.Provider value={{color:"orange", style:{width:'30px',height:'30px',margin:'0 8px 0 8px'}}}>
        
        {isLoading? '' : 
        <div style={{maxWidth:'700px',padding:'6px',margin:'12pt'}}>
            <form>

                <label htmlFor="favColor">Corresponding coordinates: </label>
                <br />
                
            </form>
            <ul>
                <li>X: {x}</li>
                <li>Y: {y}</li>
                <li>Z: {z}</li>
            </ul>
            <div className="visualization-button-center" onClick={() => setPopup(true)}>3D</div>
            
            {willOpen && 
                <div className="cover">
                    <div className="box">
                        <ImCancelCircle className="close-icon" onClick={()=>setPopup(false)}/>
                        {
                            url ? 
                            <div>
                                <Viewer url={url} numbers={resNum}/>
                                <div style={{color:"blue"}}>Location of the binding site is marked by a set of blue points.</div>

                            </div>
                            :
                            <div>
                                <Viewer file={files[0]} numbers={resNum}/>
                                <div style={{color:"blue"}}>Location of the binding site is marked by a set of blue points.</div> 
                            </div>
                        }
                        
                    </div>
                </div>
            }
            <br></br>
            {/* <button className="generate-button" style={{color:"white",backgroundColor:"grey", marginTop:"10px"}} 
                onClick={returnStep}>Return and choose a new set</button> */}
            {/* <br/> */}
            <button className="fancy-button" onClick={submitJob}>Start Generation</button>
        </div>
        }    
        </IconContext.Provider>
    )
}

export default BindingSite