import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Link, useParams } from "react-router-dom";
import { IconContext } from "react-icons";
import { HiOutlineThumbUp, HiOutlineThumbDown } from "react-icons/hi";
import { BiCommentDetail, BiNoEntry } from "react-icons/bi";
import { ImCancelCircle } from "react-icons/im";
import { useLocation } from 'react-router-dom';
import MuiSlider from "./MuiSlider.js";
import Viewer from "./Viewer.js";
import { useNavigate } from 'react-router-dom'
import { appBarClasses } from "@mui/material";
import axios from "axios";

const Status = () => {

    const navigate = useNavigate();
    const { id } = useParams();
    const [error, setError] = useState('');
    const [content, setContent] = useState(null);
    // const [currStatus, setStatus] = useState('')
    const [statusText, setStatusText] = useState('')
    const [divText, setDivText] = useState('')
    const [runInfo, setRunInfo] = useState('');

    var path = '/rate-molecule-binders/'+id+'/results'

    let qedValue = JSON.parse(localStorage.getItem('qed_value_data'));
    if((JSON.parse(localStorage.getItem('qed_value_data'))) == null) {
        qedValue = 0;
    }
    let saValue = JSON.parse(localStorage.getItem('sa_value_data'));
    if((JSON.parse(localStorage.getItem('sa_value_data'))) == null) {
        saValue = 0;
    }
    let nValue = JSON.parse(localStorage.getItem('n_value_data'));
    if((JSON.parse(localStorage.getItem('n_value_data'))) == null) {
        nValue = 0;
    }
    let oValue = JSON.parse(localStorage.getItem('o_value_data'));
    if((JSON.parse(localStorage.getItem('o_value_data'))) == null) {
        oValue = 0;
    }
    let autodockValue = JSON.parse(localStorage.getItem('autodock_value_data'));
    if((JSON.parse(localStorage.getItem('autodock_value_data'))) == null) {
        autodockValue = 0;
    }
    let hValue = JSON.parse(localStorage.getItem('h_value_data'));
    if((JSON.parse(localStorage.getItem('h_value_data'))) == null) {
        hValue = 0;
    }

    const runInfoUrl = "https://west.ucsd.edu/limo/?endpoint=run_info&target="+id;
    const url = 'https://west.ucsd.edu/limo/?endpoint=get_mols&target='+id+'&qed_slider='+qedValue
    +'&sa_slider='+saValue+'&autodock_slider='+autodockValue+'&hydrogen_bonds='+hValue;
    const url2 = 'https://west.ucsd.edu/limo/?endpoint=job_status&target_id='+id;
        useEffect(()=>{
            fetch(runInfoUrl)
            .then((responseObject) => {
                if (!responseObject.ok) {
                    throw new Error(responseObject.status);
                }
                setError(null)
                return responseObject.json();
            })
            .then((j) => {
                setRunInfo(j);              
            })
            .catch((error) => {
                setError(error.message);
            });
            axios.get(url2)
            .then((response) => {
                if (response.data.status == 'waiting') {
                    setStatusText('Run Not Started')
                    setDivText('Please wait patiently as there may be multiple runs waiting to be processed.')
                }
                else if (response.data.status == 'running') {
                    setStatusText('Running')
                    setDivText('This takes around 2-3 hours.')
                }
                else if (response.data.status == 'done') {
                    setStatusText('Run Complete')
                    setDivText('Redirecting to Results ...')
                    fetch(url)
                    .then((responseObject) => {
                        if (!responseObject.ok) {
                            throw new Error(responseObject.status);
                        }
                        setError(null)
                        return responseObject.json();
                    })
                    .then((j) => {
                        setContent(j);  
                        navigate(path, {state: {param1: j, param2: id}});
                    })
                    .catch((error) => {
                        setError(error.message);
                    });
                }
                else if (response.data.status == 'error') {
                    setStatusText('Run Error')
                    setDivText('Please try again with a different binding site or protein.')
                }
            })

        },[])


        return(
            <div className="paddings127">
                <div><h3>{statusText}</h3>
                    {divText}<br/>
                </div>
                <table>
                    <tbody>
                    <tr><td>Run ID </td> <td>{runInfo.run_id}</td></tr>
                    <tr><td>PDB</td> <td>{runInfo.pdb_name}</td></tr>
                    <tr><td>Binding site<br/>coordinates</td> <td>X: {runInfo.x}<br/>Y: {runInfo.y}<br/>Z: {runInfo.z}</td></tr>
                    <tr><td>Radius</td><td>{runInfo.radius}</td></tr>
                    <tr><td>Date started</td><td>{runInfo.date_started}</td></tr>
                    </tbody>
                </table>
            </div>
        )
    

    

}

export default Status;