import React, {useEffect} from "react";
//src: https://www.sdsc.edu/terms_of_use.html

const Intro = () => {
    useEffect(() => {
        document.title="LIMO: Latent Inceptionism for Targeted Molecule Generation";
    })
    return (
        <div className="paddings127">
            <h1 className="page-header">What Is LIMO?</h1>
            
            <div className="child-content" style={{width:"70%"}}>
                <h4>Intro</h4>
                <br/>
                {/* <h3 style={{display: "inline"}}>LIMO </h3> */}
                <h4 style={{display: "inline", textAlign:"left"}}>
                    <p>LIMO is an online application that generates 
                    and visualizes the potential molecule binders for given proteins. It allows the user
                    to choose the binding sites of the binders and provides a visualization. The user can also
                    filter the binders with specific parameters.
                    </p>
                </h4>

                <br/>
                <br/>
                <br/>

                <h4>Algorithm</h4>
                <br/>
                <div style={{textAlign:"left",}}>
                    <p>
                        LIMO generation algorithm is based on the paper <a href="https://proceedings.mlr.press/v162/eckmann22a/eckmann22a.pdf"
                        target="_blank">
                            LIMO: Latent Inceptionism for Targeted Molecule Generation</a>.
                    </p>
                    <p>
                        When you submit a job, we will first dock randomly generated molecules against your provided protein,
                         and then train a surrogate model to predict binding against your specific protein. Then, 
                         we will use LIMO to generate potential binders, which we will then dock again to extract the top binders. 
                         Finally, we will apply medicinal chemistry and synthesizability filters to remove unrealistic molecules.
                    </p>
                </div>

                <br/>
                <br/>
                <br/>

                <h4>General Background</h4>
                <br/>
                <div style={{textAlign:"left",}}>
                    <ul>
                        <p>
                        Generation of drug-like molecules with high binding affinity to target proteins remains a difficult
                        and resource-intensive task in drug discovery.
                        Existing approaches primarily employ reinforcement learning, Markov sampling, or deep generative models guided by Gaussian processes,
                        which can be prohibitively slow when generating molecules with high binding affinity calculated by computationally-expensive physicsbased methods. We present Latent Inceptionism
                        on Molecules (LIMO), which significantly accelerates molecule generation with an inceptionismlike technique. LIMO employs a variational
                        autoencoder-generated latent space and property
                        prediction by two neural networks in sequence to
                        enable faster gradient-based reverse-optimization
                        of molecular properties. Comprehensive experiments show that LIMO performs competitively on
                        benchmark tasks and markedly outperforms stateof-the-art techniques on the novel task of generating drug-like compounds with high binding affinity, reaching nanomolar range against two protein targets. We corroborate these docking-based
                        results with more accurate molecular dynamicsbased calculations of absolute binding free energy and show that one of our generated drug-like
                        compounds has a predicted K<sub>D</sub> (a measure of
                        binding affinity) of 6 · 10<sup>-14</sup> M against the human estrogen receptor, well beyond the affinities
                        of typical early-stage drug candidates and most
                        FDA-approved drugs to their respective targets.
                        Code is available at <a href="https://github.com/Rose-STL-Lab/LIMO" target="_blank">
                            https://github.com/Rose-STL-Lab/LIMO</a>.
                        </p>

                    </ul>
                </div>                   
            </div>          
        </div>
    );
};

export default Intro;