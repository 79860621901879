// NEW Reference
// https://surveyjs.io/Documentation/Library?id=get-started-react#configure-styles
// https://www.npmjs.com/package/survey-react
// reference: https://surveyjs.io/Examples/Library/?id=real-product-fit-market&platform=Reactjs&theme=defaultV2#content-js

import ReactDOM from 'react-dom'
import React, { useState, useEffect, useCallback } from "react";
import { BrowserRouter, Routes, Route, Link, useParams } from "react-router-dom";

import "survey-core/defaultV2.css";
import { StylesManager, Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios';



StylesManager.applyTheme("defaultV2");

var json = {
    "elements": [
        {
            "type": "checkbox",
            "name": "typeOfWorkplace",
            "title": "What sector are you primarily employed in?",
            "hasOther": true,
            "isRequired": true,
            "hasNone": false,
            "maxSelectedChoices": 1,
            "choices": [
                "Academia",
                "Industry",
                "Government",
                "Independent",
            ],
            "otherText": "Other",
            "colCount": 1
        }, {
            "type": "checkbox",
            "name": "profession",
            "title": "What kind of work do you mainly do?",
            "hasOther": true,
            "isRequired": true,
            "hasNone": false,
            "maxSelectedChoices": 1,
            "choices": [
                "Medicinal chemistry",
                "Computer-aided drug design",
                "Computational methods development",
                "Teaching",
            ],
            "otherText": "Other",
            "colCount": 1
        }, {
            "type": "checkbox",
            "name": "careerStage",
            "title": "What is your career stage?",
            "hasOther": true,
            "isRequired": true,
            "hasNone": false,
            "maxSelectedChoices": 1,
            "choices": [
                "Undergraduate student",
                "Graduate student (in Masters or Doctoral program)",
                "Professional student (Pharm D, MD, other)",
                "Faculty or industrial researcher",
            ],
            "otherText": "Other",
            "colCount": 1
        }
    ]
};

function sendAnswers(data, id) {
    
    let url = "https://west.ucsd.edu/limo/?endpoint=record_questionnaire&question1="
    +data.typeOfWorkplace+"&question2="+data.profession+"&question3="+data.careerStage+"&runid="+id;
    axios.get(url)
        .catch((error) => {
            alert(error); // Gives an error.
        })
}



export default function SurveyComponent() {
    useEffect(() => {
        document.title = "Survey | LIMO: Latent Inceptionism for Targeted Molecule Generation";  
    });
    const navigate = useNavigate();
    const { id } = useParams();

    const surveyComplete = useCallback((sender) => {
        sendAnswers(sender.data, id);
        navigate("/survey-submitted")
    },[])
    const survey = new Model(json);
    survey.onComplete.add(surveyComplete);
    return <Survey model={survey} />;
}
