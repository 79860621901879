import React , {useEffect} from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

const ContactUs = () => {
    useEffect(() => {
        document.title = "Contact | LIMO: Latent Inceptionism for Targeted Molecule Generation";  
      });
    return (
       
        <div className="paddings127">
            <h1 className="page-header">Contact Us</h1>
            <div className="parent-content">
                <h3>The LIMO Team</h3>
            </div>
            <div className="child-content">
                <div>
                    <h4 style={{color:'black'}}>Email: ai-drug-g@ucsd.edu</h4>
                </div>
                <br></br>
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeSc1u76r_MVObmI-AFdPrMJBHuqq9UpgBQl0_pJdzTOz7rBQ/viewform?embedded=true" width="640" height="906" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            </div>
            

            

            {/* <div className="tab-pane active" id="contactUs" style={{margin:'auto', maxWidth:'740px', 
                border:'2px solid #00C6D7',borderRadius:'25px'}}>
    
                <form className="form-contact" action="php/send-mail-contact.php">
                    <div className="row">
                        <div className="form-group">
                            <div className="col-md-6">
                                <label>Name *</label>
                                <input type="text"  required="required" defaultValue="" maxLength="100" 
                                    className="form-control" name="Name" id="name"></input>
                            </div>
                            <div className="col-md-6">
                                <label>Email *</label>
                                <input type="email"  required="required" defaultValue="" maxLength="100" 
                                    className="form-control" name="Email" id="email"></input>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group">
                            <div className="col-md-12">
                                <label>Comment *</label>
                                <textarea maxLength="5000" rows="10" className="form-control" name="message" 
                                    style={{height: '138px',}} required="required" ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <input type="submit" defaultValue="Send Message" className="btn127" 
                                style={{border:'0px',borderRadius:'5px', backgroundColor:'#00C6D7',color:'white'}}>
                            </input>
                        </div>
                    </div>
            </form>
                <div className="result"></div>
            </div> */}

                        
        </div>
    );
};

export default ContactUs;